import MeetYourCoaches from "@/sections/MeetYourCoaches"
import SaveTheDateSection from "@/sections/SaveTheDateSection"
import StayInTheKnow from "@/sections/StayInTheKnow"
import TestimonialsSection from "@/sections/TestimonalsSection"
import WeAreHere from "@/sections/WeAreHere"
import WhatWeDoSection from "@/sections/WhatWeDoSection"

const HomePageBottom = () => {
  return (
    <>
      <WhatWeDoSection />
      <div className="hidden lg:block">
        <TestimonialsSection />
      </div>
      {/* {trainers.length > 0 && } */}
      <MeetYourCoaches />
      <SaveTheDateSection />
      {/* TODO: Instagram posts */}
      <div className="my-[50px]"></div>
      <StayInTheKnow />
      <WeAreHere />
    </>
  )
}

export default HomePageBottom
