import Image from "next/image"
import Link from "next/link"
import { FC } from "react"
import Skeleton from "react-loading-skeleton"

const SaveTheDateCard: FC<{
  imageUrl: string
  title: string
  subtitle: string
  date: string
  link: string
}> = ({ imageUrl, title, date, link }) => {
  return (
    <div className="sm:w-[360px] w-[270px] flex flex-col rounded-xl overflow-hidden border border-gray-200 shadow-xl">
      <div className="flex-1 grid grid-rows-[375px_auto] sm:grid-rows-[500px_auto]">
        <div className="sm:w-[360px] w-[270px]  relative h-[375px] sm:h-[500px] overflow-hidden">
          <Image
            className="object-cover object-center"
            src={imageUrl}
            alt={title}
            fill
            loading="lazy"
          />
        </div>
        <h4 className="self-center px-2 py-4 text-xl font-bold text-center font-display">
          {title}
        </h4>
        {/* <p className="pb-4 text-sm text-center text-gray-600 ">{subtitle}</p> */}
      </div>
      <Link aria-label={`View ${title}`} href={link}>
        <div className="flex items-center justify-center gap-2 py-4 text-lg font-medium text-center bg-text text-brand">
          <img src="/icons/calender.svg" alt="calender" />
          <span>
            {new Date(date).toLocaleDateString("en-US", {
              dateStyle: "long",
            })}
          </span>
        </div>
      </Link>
    </div>
  )
}

export const SaveTheDateCardSkeleton: FC = () => {
  return (
    <Skeleton className="w-[360px] h-[375px] rounded-xl overflow-hidden border border-gray-200 shadow-xl" />
  )
}

export default SaveTheDateCard
