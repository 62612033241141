import BsFillCaretLeftFill from "@/public/icons/BsFillCaretLeftFill.svg"
import BsFillCaretRightFill from "@/public/icons/BsillCaretRightFill.svg"
import axios from "axios"

import { useQueries } from "@tanstack/react-query"
import { FC } from "react"
import Carousel, { ButtonGroupProps } from "react-multi-carousel"

import { Event } from "@/lib/api/strapi/getEvents"
import useInView from "@/lib/hooks/useInView"

import SaveTheDateCard, {
  SaveTheDateCardSkeleton,
} from "@/components/SaveTheDateCard"

const SaveTheDateSection: FC = () => {
  const [loadIframe, divRef] = useInView({ threshold: 0.05 })
  const [{ data, isLoading }] = useQueries({
    queries: [
      {
        queryKey: ["events"],
        queryFn: async () => {
          const e = await axios.get<{ events: Event[] }>(`/api/getEvents`)
          // const e = await getEvents()
          return e.data.events
        },
        enabled: loadIframe,
      },
    ],
  })

  return (
    <>
      {(isLoading || (data || []).length > 0) && (
        <section
          className="mb-[75px] lg:mb-[134px] w-[90vw] max-w-screen-2xl mx-auto px-2"
          ref={divRef}
        >
          <div className="flex flex-col mb-[24px] lg:mb-[48px] items-center">
            {loadIframe && (
              <img
                loading="lazy"
                height={75}
                className="lg:w-[75px] object-center object-contain w-[56px]"
                src="/icons/save-the-date.webp"
                alt="save the date"
              />
            )}
            <h2 className="mt-4 text-2xl font-bold text-center md:text-3xl lg:text-4xl font-display">
              BFB EVENTS
            </h2>
          </div>
          {loadIframe && (
            <SaveTheDateCarousel isLoading={isLoading} events={data || []} />
          )}
        </section>
      )}
    </>
  )
}

const getResponsive = () => ({
  mobile: {
    breakpoint: { min: 0, max: 4000 },
    items: 1,
  },

  desktopSmall: {
    breakpoint: { min: 760, max: 1128 },
    items: 2,
  },
  desktop: {
    breakpoint: { min: 1128, max: 4000 },
    items: 3,
  },
})
const SaveTheDateCarousel: FC<{
  events: Event[]
  isLoading: boolean
}> = ({ events, isLoading }) => {
  return (
    <div className="relative">
      <Carousel
        containerClass="save-the-date-carousel pb-[60px] max-w-[1146px] mx-auto"
        itemClass="justify-center flex"
        responsive={getResponsive()}
        customButtonGroup={
          <ButtonGroup numberOfItems={isLoading ? 3 : events.length} />
        }
        arrows={false}
        ssr
        key={events?.length || 0}
      >
        {isLoading &&
          new Array(3)
            .fill(null)
            .map((_, i) => <SaveTheDateCardSkeleton key={i} />)}
        {!isLoading &&
          events.map((event) => (
            <SaveTheDateCard
              key={event.id}
              imageUrl={event.attributes.coverImage.data.attributes.url}
              date={event.attributes.startDate}
              subtitle={event.attributes.title}
              title={event.attributes.title}
              link={`/event/${event.id}`}
            />
          ))}
      </Carousel>
    </div>
  )
}

const ButtonGroup = ({
  next,
  previous,
  numberOfItems,
  ...rest
}: ButtonGroupProps & {
  numberOfItems: number
}) => {
  const currentSlide = rest.carouselState!.currentSlide!
  return (
    <div className="absolute   top-1/2 bottom-1/2  justify-between gap-24 -left-[30px] -right-[30px] h-[40px]  flex  items-center">
      <button
        aria-label="Previous Slide"
        className="p-3 text-white rounded-full disabled:bg-gray-400 bg-brand"
        onClick={() => previous!()}
        disabled={currentSlide === 0}
      >
        <BsFillCaretLeftFill className="w-7 h-7" />
      </button>
      <button
        aria-label="Next Slide"
        className="p-3 text-white rounded-full disabled:bg-gray-400 bg-brand"
        onClick={() => next!()}
        disabled={currentSlide === numberOfItems - 1}
      >
        <BsFillCaretRightFill className="w-7 h-7" />
      </button>
    </div>
  )
}

export default SaveTheDateSection
