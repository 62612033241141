import { FC } from "react"
import Skeleton from "react-loading-skeleton"

import useInView from "@/lib/hooks/useInView"

import TestimonialIcon from "public/icons/testimonials.svg"

const TestimonialsSection: FC = () => {
  const [loadIframe, iframeRef] = useInView()

  return (
    <section className="w-[90vw] -mb-[205px] md:-mb-[290px] lg:-mb-[395px] mx-auto  ">
      <div
        className="flex flex-col items-center

      pb-[175px] md:pb-[250px] lg:pb-[395px] text-white bg-text rounded-xl"
      >
        <TestimonialIcon />
        <h2 className="px-1 mb-12 font-bold text-center lg:mb-24 font-display">
          <span className="text-xl md:text-2xl lg:text-3xl text-brand">
            TESTIMONIALS
          </span>
          <br />
          <span className="text-lg text-white md:text-xl lg:text-2xl">
            THINGS PEOPLE SAY ABOUT US
          </span>
        </h2>
      </div>
      <div
        className="w-10/12 h-[350px]  -translate-y-1/2 md:h-[500px] lg:h-[790px] mx-auto"
        ref={iframeRef}
      >
        {loadIframe ? (
          <iframe
            // width="1282"
            // height="719"
            src="https://www.youtube.com/embed/ay2N_KbJXos"
            title="WHAT DO YOU THINK ABOUT BODY FACTORY BALI?"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            className="w-full h-full rounded-xl"
          ></iframe>
        ) : (
          <Skeleton className="w-full h-full rounded-xl" />
        )}
      </div>
    </section>
  )
}

export default TestimonialsSection
