import { FC } from "react"
import Skeleton from "react-loading-skeleton"

import useInView from "@/lib/hooks/useInView"

const MapBox: FC = () => {
  const [loadIframe, iframeRef] = useInView()

  return (
    <>
      {loadIframe ? (
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15777.492792196408!2d115.1348921!3d-8.656011!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd24756803b6cdd%3A0x2c12b148b65fb!2sBody%20Factory%20Bali!5e0!3m2!1sen!2sin!4v1697572337887!5m2!1sen!2sin"
          className="w-full h-[614px]"
          title="Body Factory Bali"
          loading="lazy"
        ></iframe>
      ) : (
        <div ref={iframeRef}>
          <Skeleton height={614} className="w-full" />
        </div>
      )}
    </>
  )
}

export default MapBox
