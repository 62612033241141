import Image from "next/image"
import { FC } from "react"
import Skeleton from "react-loading-skeleton"

import useInView from "@/lib/hooks/useInView"

const StayInTheKnow: FC = () => {
  const [isInView, divRef] = useInView()
  return (
    <section className="lg:w-[90vw]   md:max-w-screen-md lg:max-w-[1500px] mx-auto mb-[75px] lg:mb-[134px]">
      <div
        ref={divRef}
        className="grid border group w-[361px] max-w-full md:w-[590px]  md:max-w-screen-md lg:max-w-full mx-auto lg:w-auto border-gray-200 shadow-xl grid-cols-1 transition-colors duration-150 ease-out bg-white  hover:bg-brand overflow-hidden group lg:grid-cols-2 rounded-xl"
      >
        <div className="relative w-[361px] max-w-full md:w-[590px] h-[450px]">
          {!isInView && (
            <Skeleton
              className={`object-cover object-center transition-all duration-150 ease-out`}
            />
          )}
          {isInView && (
            <Image
              src="/images/stay-in-the-know.webp"
              alt="stay in the know"
              fill
              className="object-cover object-center transition-all duration-150 ease-out group-hover:scale-110"
              loading="lazy"
            />
          )}
        </div>
        <div className="flex flex-col items-center justify-center px-3 py-12 md:p-12">
          <div
            style={{
              maxWidth: "490px",
            }}
          >
            <h3 className="text-xl font-bold lg:text-3xl font-display">
              STAY IN THE KNOW
            </h3>
            <p className="mt-2 text-lg">
              Get the latest articles, updates and recommendations from the Body
              Factory Bali team. We send emails weekly.
            </p>
            <div className="relative mt-2 overflow-hidden rounded-lg">
              <input
                className="w-full text-xs md:text-base px-4 flex items-center h-[50px] bg-gray-200 rounded-lg"
                placeholder="Leave us your email we won't spam :)"
                type="email"
              />
              {isInView && (
                <button
                  aria-label="Subscribe"
                  className="absolute flex justify-center items-center h-[50px]   top-0 bg-cover bg-no-repeat group-hover:bg-[url('/icons/skewed-left-black.svg')] w-[120px] md:w-[168.55px] right-0 bg-[url('/icons/skewed-left-yellow.svg')]"
                >
                  <span className="text-xs font-bold md:text-lg group-hover:text-white">
                    SUBSCRIBE
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default StayInTheKnow
