import { FC } from "react"

import MapBox from "@/components/Map"

import MapPinIcon from "public/icons/map-pin.svg"

const WeAreHere: FC = () => {
  return (
    <section className="lg:w-[95vw] mx-auto">
      <div className="flex flex-col items-center">
        <MapPinIcon className="lg:h-[75px] h-[56px]" />
        <h2 className="max-w-3xl text-2xl md:text-3xl lg:text-4xl mt-[20px] mb-[24px] lg:mb-[48px] text-center font-display">
          <span className="text-text">WE ARE</span>
          <span className="text-brand">HERE</span>
        </h2>
      </div>
      <MapBox />
    </section>
  )
}

export default WeAreHere
