import { FC } from "react"

import useInView from "@/lib/hooks/useInView"

import WhatWeDoCard from "@/components/WhatWeDoCard"

import WhatWeDoIcon from "public/icons/what-we-do.svg"

const WhatWeDoSection: FC = () => {
  const [isInView, divRef] = useInView()
  return (
    <section
      ref={divRef}
      className="px-5 mx-auto  mb-[75px] max-w-[90vw]  lg:mb-[134px] "
    >
      <div className="flex flex-col items-center ">
        <WhatWeDoIcon className="inline-block" />
        <h2 className="font-display text-lg md:text-xl lg:text-2xl text-center mt-[20px] mb-[24px] lg:mb-[58px]">
          <span className="text-text">WHAT WE DO</span>
          <br />
          <span className="text-brand">
            A 360° APPROACH TO HEALTH & FITNESS
          </span>
        </h2>
        <div className="flex flex-col justify-around gap-16 lg:gap-12 lg:flex-row">
          <WhatWeDoCard
            imageSrc="/images/what-we-do/perform.jpg"
            link="/perform"
            title="Perform"
            isInView={isInView}
            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAIAAAAmkwkpAAAACXBIWXMAAAPoAAAD6AG1e1JrAAAAP0lEQVR4nAE0AMv/AFFRUXFpaMnFxPv7+wCdm5sdAABlSjvl4uIApaWlJBkXPDMxzcrLALa2tiAaGIiDf5aXlIHNGADNDeHIAAAAAElFTkSuQmCC"
          />
          <WhatWeDoCard
            imageSrc="/images/what-we-do/sleep.jpg"
            link="/sleep"
            title="Sleep"
            isInView={isInView}
            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAIAAAAmkwkpAAAACXBIWXMAAAPoAAAD6AG1e1JrAAAAP0lEQVR4nAE0AMv/AODs7/j9/x8kJQAKDwDy+/+9tq8fBgAABAcAu7WyvbWtblRHmJiYAJWUh4SKemRfRa2vkJk7GK0zPdNNAAAAAElFTkSuQmCC"
          />
          <WhatWeDoCard
            imageSrc="/images/what-we-do/meal-plans.jpg"
            link="/nutrition-shop"
            title="Meal Plans"
            isInView={isInView}
            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAIAAAAmkwkpAAAACXBIWXMAAAPoAAAD6AG1e1JrAAAAP0lEQVR4nAE0AMv/ANra2t3f3rqwqcjIyAD0+Pr9//+mfmmyo5UAzs7F3tTFDgIAi3JfAIJgSK+hlG9mX6SKepTsHxpAPYhVAAAAAElFTkSuQmCC"
          />
        </div>
      </div>
    </section>
  )
}

export default WhatWeDoSection
