import Image from "next/image"
import Link from "next/link"
import { FC } from "react"

import SkewedButton from "./ui/skewed-button"

const WhatWeDoCard: FC<{
  imageSrc: string
  title: string
  link: string
  blurDataURL: string
  isInView: boolean
}> = ({ title, imageSrc, link, isInView, blurDataURL }) => {
  return (
    <div className="w-[360px] max-w-[90vw] relative  group shadow-xl rounded-xl border border-gray-200 bg-white">
      <h4 className="py-4 text-xl italic font-bold text-center transition-all duration-300 ease-out bg-white rounded-t-xl group-hover:bg-text group-hover:text-white font-display">
        {title}
      </h4>
      <div className="relative overflow-hidden rounded-b-xl max-w-[90vw] w-[360px] h-[350px]">
        <Image
          className="object-cover object-center transition-transform duration-300 ease-out group-hover:scale-110 "
          src={isInView ? imageSrc : blurDataURL}
          alt={title}
          width={720}
          height={700}
          loading="lazy"
        />
      </div>
      <div className="absolute group-hover:translate-y-[50px] transition-all duration-300 ease-out -bottom-[25px] left-0 right-0 flex justify-center w-full">
        <SkewedButton className="inline-block font-bold transition-colors duration-300 ease-out hover:text-brand hover:bg-text text-text bg-brand">
          <Link aria-label={`Learn more about ${title}`} href={link}>
            LEARN MORE
          </Link>
        </SkewedButton>
      </div>
    </div>
  )
}

export default WhatWeDoCard
